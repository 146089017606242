// Old - for compatibility only (depracated)
const selectElement = (Selector) => { return document.querySelector(Selector); }
const selectElements = (Selector) => { return document.querySelectorAll(Selector); }
const selectElementById = (ID) => { return document.getElementById(ID); }
const selectElementsByClass = (Class) => { return document.getElementsByClassName(Class); }
const selectElementsByTag = (Tag) => { return document.getElementsByTagName(Tag); }

// Current
const querySelect = {
    element: (Selector) => { return document.querySelector(Selector); },
    elements: (Selector) => { return document.querySelectorAll(Selector); },
    byId: (ID) => { return document.getElementById(ID); },
    byClass: (Class) => { return document.getElementsByClassName(Class); },
    byTag: (Tag) => { return document.getElementsByTagName(Tag); }
}

const CHAR_EMPTY = '';
const CHAR_SPACE = ' ';
const CHAR_COMMA = ',';
const CHAR_DOT = '.';
const REGEX = {
    EMAIL: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
}

const SIZE = {
    KB: 1024,
    MB: 1050000,
    GB: 1075200000
}

// IE11 Detection
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if (!localStorage.hasOwnProperty('doCacheClearing')) { localStorage.setItem('doCachClearing','true')};

// Adding parent element classes to another element
const inheritClasses = (ParentClasses, TargetBox, ClassNameToFind) => {
    var ClassesCount = ParentClasses.length;
    for (var i = 0; i < ClassesCount; i++) {
        if (ParentClasses[i].indexOf(ClassNameToFind) !== -1) {
            TargetBox.addClass(ParentClasses[i].toString());
        }
    }
}
// Preloader
const hidePreloader = function (timeout) {
    setTimeout(function(){ 
        (function() {
            function fadeOutPreloader() { $('.preloader').fadeOut('slow'); }
            if (document.readyState === "loading") {
                document.addEventListener("DOMContentLoaded", fadeOutPreloader);
            } else { fadeOutPreloader(); }
        })();
    }, timeout);
}

// Check viewport
function OnViewport (minMax, width, successCallback, failureCallback) {
    this.success = successCallback;
    this.failure = failureCallback;
    this.query = window.matchMedia("({0}-width: {1}px)".format(minMax, width.toString()));
    this.query.success = this.success;
    this.query.failure = this.failure;
    this.query.onchange = function (event) { (event.matches) ? this.success() : this.failure(); }
    this.matched = function(matchQuery) { (matchQuery.matches) ? this.success() : this.failure(); }
}

// ############# CUSTOM PROTOTYPE ELEMENTS #############

// String format => string.format(replacement1, replacement2)
if (!String.prototype.format) {
    String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ?
                args[number] :
                match;
        });
    };
}

//Gets date in yyyy-mm-dd format => optional parameter for separator between "yyyy", "mm" and "dd"
if (!Date.prototype.yyyymmdd) {
    Date.prototype.yyyymmdd = function (separator = '-') {
        function twoDigit(n) { return (n < 10 ? '0' : '') + n; }
        return '' + this.getFullYear() + separator + twoDigit(this.getMonth() + 1) + separator + twoDigit(this.getDate());
    }
}

//Gets date before the number of days from today
if (!Date.prototype.daysEarlier) {
    Date.prototype.daysEarlier = function (number = 0) {
        return new Date(new Date().setDate(new Date().getDate()-number))
    }
}

//Gets date after the number of days from today
if (!Date.prototype.daysAhead) {
    Date.prototype.daysAhead = function (number = 0) {
        return new Date(new Date().setDate(new Date().getDate()+number))
    }
}

//Date get dates from range (array) => new Date('startDate').getRangeTo('endDate') -> format: yyyy-mm-dd
if (!Date.prototype.getDatesArray) {
    Date.prototype.getDatesArray = function(endDate, format = 'yyyy-mm-dd') {
        var startDate = this.yyyymmdd()
        var dates = new Array(), date = new Date(startDate);
        while (date <= new Date(endDate)) {
            if (format == 'yyyy-mm-dd') {
                dates.push(new Date(date).yyyymmdd('-'));
            }
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }
}


// Element children removal => dom_element.removeChildNodes()
if (!Element.prototype.removeChildNodes) {
    Element.prototype.removeChildNodes = function () {
        while (this.firstChild)
        { 
            this.removeChild(this.firstChild); 
        }
    };
}

// ########### CUSTOM PROTOTYPE ELEMENTS - END ###########


// Limiting text size
const limitWords = (words_string, maximum_length, words_separator = ' ') => {
    if (!words_string || words_string.length <= maximum_length) return words_string;
    return words_string.substr(0, words_string.lastIndexOf(words_separator, maximum_length));
}
// Limiting text size with trailing dots
const limitWordsSuffixed = (words_string, length, separator, suffix ) => {
    var lastSeparatorIndex = new Number(), selectedWords = new String();

    if (words_string.length <= length) return words_string;
    selectedWords = words_string.substr(0, length+separator.length);
    lastSeparatorIndex = selectedWords.lastIndexOf(separator);
    if (lastSeparatorIndex >= 0) selectedWords = selectedWords.substr(0, lastSeparatorIndex);
    if (selectedWords) selectedWords += suffix;
    return selectedWords;
}

// MD5 string encryption
const StringToMD5 = function (string) {

    function RotateLeft(lValue, iShiftBits) {
            return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
    }
 
    function AddUnsigned(lX,lY) {
            var lX4,lY4,lX8,lY8,lResult;
            lX8 = (lX & 0x80000000);
            lY8 = (lY & 0x80000000);
            lX4 = (lX & 0x40000000);
            lY4 = (lY & 0x40000000);
            lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);
            if (lX4 & lY4) {
                    return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
            }
            if (lX4 | lY4) {
                    if (lResult & 0x40000000) {
                            return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
                    } else {
                            return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
                    }
            } else {
                    return (lResult ^ lX8 ^ lY8);
            }
    }
 
    function F(x,y,z) { return (x & y) | ((~x) & z); }
    function G(x,y,z) { return (x & z) | (y & (~z)); }
    function H(x,y,z) { return (x ^ y ^ z); }
    function I(x,y,z) { return (y ^ (x | (~z))); }
 
    function FF(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
    };
 
    function GG(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
    };
 
    function HH(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
    };
 
    function II(a,b,c,d,x,s,ac) {
            a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
            return AddUnsigned(RotateLeft(a, s), b);
    };
 
    function ConvertToWordArray(string) {
            var lWordCount;
            var lMessageLength = string.length;
            var lNumberOfWords_temp1=lMessageLength + 8;
            var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;
            var lNumberOfWords = (lNumberOfWords_temp2+1)*16;
            var lWordArray=Array(lNumberOfWords-1);
            var lBytePosition = 0;
            var lByteCount = 0;
            while ( lByteCount < lMessageLength ) {
                    lWordCount = (lByteCount-(lByteCount % 4))/4;
                    lBytePosition = (lByteCount % 4)*8;
                    lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));
                    lByteCount++;
            }
            lWordCount = (lByteCount-(lByteCount % 4))/4;
            lBytePosition = (lByteCount % 4)*8;
            lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);
            lWordArray[lNumberOfWords-2] = lMessageLength<<3;
            lWordArray[lNumberOfWords-1] = lMessageLength>>>29;
            return lWordArray;
    };
 
    function WordToHex(lValue) {
            var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;
            for (lCount = 0;lCount<=3;lCount++) {
                    lByte = (lValue>>>(lCount*8)) & 255;
                    WordToHexValue_temp = "0" + lByte.toString(16);
                    WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);
            }
            return WordToHexValue;
    };
 
    function Utf8Encode(string) {
            string = string.replace(/\r\n/g,"\n");
            var utftext = "";
 
            for (var n = 0; n < string.length; n++) {
 
                    var c = string.charCodeAt(n);
 
                    if (c < 128) {
                            utftext += String.fromCharCode(c);
                    }
                    else if((c > 127) && (c < 2048)) {
                            utftext += String.fromCharCode((c >> 6) | 192);
                            utftext += String.fromCharCode((c & 63) | 128);
                    }
                    else {
                            utftext += String.fromCharCode((c >> 12) | 224);
                            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                            utftext += String.fromCharCode((c & 63) | 128);
                    }
 
            }
 
            return utftext;
    };
 
    var x=Array();
    var k,AA,BB,CC,DD,a,b,c,d;
    var S11=7, S12=12, S13=17, S14=22;
    var S21=5, S22=9 , S23=14, S24=20;
    var S31=4, S32=11, S33=16, S34=23;
    var S41=6, S42=10, S43=15, S44=21;
 
    string = Utf8Encode(string);
 
    x = ConvertToWordArray(string);
 
    a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;
 
    for (k=0;k<x.length;k+=16) {
            AA=a; BB=b; CC=c; DD=d;
            a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);
            d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);
            c=FF(c,d,a,b,x[k+2], S13,0x242070DB);
            b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);
            a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);
            d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);
            c=FF(c,d,a,b,x[k+6], S13,0xA8304613);
            b=FF(b,c,d,a,x[k+7], S14,0xFD469501);
            a=FF(a,b,c,d,x[k+8], S11,0x698098D8);
            d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);
            c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);
            b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);
            a=FF(a,b,c,d,x[k+12],S11,0x6B901122);
            d=FF(d,a,b,c,x[k+13],S12,0xFD987193);
            c=FF(c,d,a,b,x[k+14],S13,0xA679438E);
            b=FF(b,c,d,a,x[k+15],S14,0x49B40821);
            a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);
            d=GG(d,a,b,c,x[k+6], S22,0xC040B340);
            c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);
            b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);
            a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);
            d=GG(d,a,b,c,x[k+10],S22,0x2441453);
            c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);
            b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);
            a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);
            d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);
            c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);
            b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);
            a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);
            d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);
            c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);
            b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);
            a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);
            d=HH(d,a,b,c,x[k+8], S32,0x8771F681);
            c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);
            b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);
            a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);
            d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);
            c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);
            b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);
            a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);
            d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);
            c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);
            b=HH(b,c,d,a,x[k+6], S34,0x4881D05);
            a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);
            d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);
            c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);
            b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);
            a=II(a,b,c,d,x[k+0], S41,0xF4292244);
            d=II(d,a,b,c,x[k+7], S42,0x432AFF97);
            c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);
            b=II(b,c,d,a,x[k+5], S44,0xFC93A039);
            a=II(a,b,c,d,x[k+12],S41,0x655B59C3);
            d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);
            c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);
            b=II(b,c,d,a,x[k+1], S44,0x85845DD1);
            a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);
            d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);
            c=II(c,d,a,b,x[k+6], S43,0xA3014314);
            b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);
            a=II(a,b,c,d,x[k+4], S41,0xF7537E82);
            d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);
            c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);
            b=II(b,c,d,a,x[k+9], S44,0xEB86D391);
            a=AddUnsigned(a,AA);
            b=AddUnsigned(b,BB);
            c=AddUnsigned(c,CC);
            d=AddUnsigned(d,DD);
            }
 
        var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);
 
        return temp.toLowerCase();
 }

// String encoding
const encodeEntities = (str) => {
    return str.replace(/./gm, function(s) {
        return "&#" + s.charCodeAt(0) + ";";
    });
}
const encodeEntitiesURI = (str) => {
    return encodeURIComponent(encodeEntities(str));
}

// String decoding
const decodeEntities = (str) => {
    return (str + "").replace(/&#\d+;/gm,function(s) {
        return String.fromCharCode(s.match(/\d+/gm)[0]);
    })
};
const decodeEntitiesURI = (str) => { 
    return decodeEntities(URIDecoder(str));
}

function URIDecoder(val)
{
    val=val.replace(/\+/g, '%20');
    var str=val.split("%");
    var cval=str[0];
    for (var i=1;i<str.length;i++)
    {
        cval+=String.fromCharCode(parseInt(str[i].substring(0,2),16))+str[i].substring(2);
    }

    return cval;
}

$.fn.hasAttr = function(name) {  
    return this.attr(name) !== undefined;
};


const page = {
    reload: () => {
        // Reload current page
        document.location.reload();
    },
    file: () => {
        // Get page filename
        var url = document.location.pathname
        return url.substring(url.lastIndexOf('/')+1)
    }
}

// Read url parameters
const queryString = {
    get: (queryString) => {
        var results = new RegExp('[\?&]' + queryString + '=([^&#]*)').exec(window.location.href);
        if (results === null){ return null; } else { return results[1] || 0; }
    }
}

const clipboard = {
    copy: (Selector) => {
        var value = new String();
        if (querySelect.byId(Selector).value === undefined) {
            value = querySelect.byId(Selector).dataset.value;
        } else {
            value = querySelect.byId(Selector).value;
        }
        var tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }
}

const selectItem = (listItem, input) => {
    var listItemValue = listItem.innerHTML;
    if (listItemValue !== "") {
        input.value = listItemValue;
        // IE11 Support
        let new_event = document.createEvent("Event");
        new_event.initEvent("change", false, true);
        input.dispatchEvent(new_event);
        //input.dispatchEvent(new Event('change'));
    }
}

const LAST_IN_ARR = (array) => { return array.indexOf(array.slice(-1)[0]); };
const SECOND_TO_LAST_IN_ARR = (array) => { return array.indexOf(array.slice(-2)[0]); };

const searchArray = (array, query) => {
    return array.filter((el) =>
        el.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
}

const findInArray = (array, query) => {
    return array.filter((el) =>
        el.toLowerCase() === query.toLowerCase()
    );
}

const filterArray = (arr, filter, matching) => {
    let i = arr.length;

    switch (matching) {
        case true:
            while (i--) {
                if (arr[i].indexOf(filter) > 0) { arr.splice(i, 1); }
            }
            break;
    
        case false:
            while (i--) {
                if (arr[i].indexOf(filter) < 0) { arr.splice(i, 1); }
            }
            break;
    }
}

const distinctArray = (arr) => {
    arr = [...new Set(arr)];
    return arr;
} 

const redirectToPage = (pageUrl) => {
    document.location = pageUrl;
}

const downloadClick = (filePath) => {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.download = name;
    a.href = filePath;
    a.click();
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

function checkIfClicked(element, elementTrigger, callback) {
    let usesTrigger = new Boolean();
    if (typeof elementTrigger === 'undefined') { usesTrigger = false } else { usesTrigger = true };

    document.body.addEventListener('click', function (event) {
        if (usesTrigger) {
            if (element.contains(event.target) || elementTrigger.contains(event.target)) {
                callback.success && callback.success();
            } else {
                callback.fail && callback.fail();
            }
        } else {
            if (element.contains(event.target)) {
                callback.success && callback.success();
            } else {
                callback.fail && callback.fail();
            }
        }
    });
}

//======================================================================
//======================================================================

const APP_CONFIG = new Object();
APP_CONFIG.VERSION = "1.0.0 Release Candidate";
APP_CONFIG.MODE = "PRODUCTION";

//APP_CONFIG.LOCATION = window.location.host;

switch (APP_CONFIG.MODE) {
    case "PRODUCTION":
        APP_CONFIG.LOCATION = '/';
        APP_CONFIG.ORIGIN_LOCATION = window.location.origin + '/';
        APP_CONFIG.UPLOADS_PATH = window.location.origin + '/Uploads/';
    break;
    case "DEVELOPMENT":
        APP_CONFIG.LOCATION = '/ServiceCenter';
        APP_CONFIG.ORIGIN_LOCATION = window.location.origin + '/ServiceCenter' + '/';
        APP_CONFIG.UPLOADS_PATH = window.location.origin + '/ServiceCenter/Uploads/';        
    break;
}